<template>
  <div class="sela-container">
    <HomeOffersGroupSlider :enable-cache="true" class="my-5" />

    <MaterialsGrid
      class="my-5"
      key-cache="offers-grid"
      :fetch-params="fetchParams"
      with-query-params
    />
  </div>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()

const fetchParams = computed(() => {
  return { offersOnly: true, ...useRoute().query }
})

useServerSeoMeta(
  useOgMeta(
    url + '/offers',
    t('offers_discounts'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)

useHead({
  title: t('offers_discounts'),
  link: [useSeoCanonical(url + '/offers')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('offers_discounts'), url + '/offers')
    ])
  ]
})
</script>
